<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue'
import { breakpointsTailwind, useBreakpoints, useScroll } from '@vueuse/core'
import { useCookies } from '@vueuse/integrations/useCookies'
import PromoHeaderBanner from './PromoHeaderBanner.vue'
import { headerBannerHeight } from '../store'
import useMounted from '@/utilities/useMounted'
import getScrollbarWidth from '@/utilities/scrollbarWidth'

const props = defineProps<{
  white?: boolean
  promoHeaderBanner?: {
    slug: string
    description?: string
    link?: string
  }
}>()

const SCROLL_CHANGE_THRESHOLD = 80

const { arrivedState } = import.meta.env.SSR
  ? { arrivedState: { top: true } }
  : useScroll(window, { offset: { top: SCROLL_CHANGE_THRESHOLD } })

const scrolled = computed(() => !arrivedState.top)

const { md } = useBreakpoints(breakpointsTailwind)

const mounted = useMounted()
const preMounted = ref(false)
const scrollbarWidth = ref()

onMounted(() => {
  preMounted.value = true
  if (window.scrollY > SCROLL_CHANGE_THRESHOLD) {
    arrivedState.top = false
  }
  calculateBannerHeight()

  scrollbarWidth.value = getScrollbarWidth()
})

function calculateBannerHeight() {
  headerBannerHeight.value = $promoHeaderBannerEl.value?.clientHeight || 0
}

watch(md, calculateBannerHeight)

const cookieId = `cypress-promo-banner-${props.promoHeaderBanner?.slug}`
const cookie = ref<ReturnType<typeof useCookies>>()

const scrollDrivenAnimationSupport = ref(false)

onMounted(() => {
  cookie.value = useCookies()
  scrollDrivenAnimationSupport.value = window.CSS.supports('animation-range', '0vh 10vh')
})

const expiredDate = computed(() => {
  const today = new Date()
  const in6years = new Date(today.setFullYear(today.getFullYear() + 6))
  return in6years
})

function hideBanner() {
  cookie.value?.set(cookieId, true, { expires: expiredDate.value })
}

const showBanner = computed(() => {
  return mounted.value && !cookie.value?.get(cookieId)
})

const $promoHeaderBanner = ref<InstanceType<typeof PromoHeaderBanner>>()
const $promoHeaderBannerEl = computed(() => $promoHeaderBanner.value?.$el)
</script>

<template>
  <header
    class="fixed top-0 z-100 w-screen md:pr-[15px]"
    :class="{
      'white-header border-b backdrop-blur': white,
      'border-b-gray-50 bg-white/90': white && scrolled && !scrollDrivenAnimationSupport,
      'border-b-transparent bg-white/0': white && !scrolled && !scrollDrivenAnimationSupport,
      // force repaint on mount to avoid refresh bug
      'transition-colors duration-500 ease-in-out': mounted && !scrollDrivenAnimationSupport,
    }"
    :style="{
      transform: `translateY(${
        preMounted && props.promoHeaderBanner && (!showBanner || scrolled) ? `-${headerBannerHeight}px` : 0
      })`,
      paddingRight: scrollbarWidth === undefined ? undefined : `${scrollbarWidth}px`,
    }"
    data-name="main-header"
  >
    <PromoHeaderBanner
      v-if="props.promoHeaderBanner"
      ref="$promoHeaderBanner"
      :class="{
        'absolute scale-y-0 transition-transform': !preMounted,
      }"
      :description="props.promoHeaderBanner.description"
      :link="props.promoHeaderBanner.link"
      @hide-banner="hideBanner"
    >
      <template #icon-mobile>
        <slot name="icon-mobile" />
      </template>
      <template #icon-desktop>
        <slot name="icon-desktop" />
      </template>
      <slot name="promo-header-banner" />
    </PromoHeaderBanner>
    <slot />
  </header>
  <div
    v-if="props.promoHeaderBanner && showBanner"
    class="h-[44px] md:hidden"
    :style="{
      height: `${headerBannerHeight}px`,
    }"
  />
</template>

<style>
.mobile-navbar-open header nav {
  @apply max-sm:bg-white;
}

@supports (animation-range: 0vh 10vh) {
  header.white-header {
    animation: fade-background linear forwards;
    animation-timeline: scroll();
    animation-range: 0vh 10vh;
  }
}

@keyframes fade-background {
  from {
    @apply border-b-gray-50/0 bg-white/0;
  }
  to {
    @apply border-b-gray-50/100 bg-white/90;
  }
}
</style>
