<script lang="ts" setup>
import { IconActionDeleteLarge, IconActionDeleteMedium, IconArrowRight } from '@cypress-design/vue-icon'

withDefaults(
  defineProps<{
    description?: string
    link?: string
  }>(),
  {
    description: 'Learn More',
    link: undefined,
  },
)

const emit = defineEmits(['hide-banner'])
</script>

<template>
  <div
    data-cy="promo-header-banner"
    class="flex min-h-[48px] transform flex-col gap-[4px] bg-indigo-50 py-[8px] pl-[48px] pr-[16px] text-primary sm:mr-[-20px] sm:pr-[32px] md:flex-row md:items-center md:justify-center"
  >
    <slot name="icon-desktop" />
    <slot name="icon-mobile" />
    <div class="align-bottom text-[16px] leading-[24px] text-gray-1000"><slot /></div>
    <span class="hidden text-[18px] leading-[28px] text-gray-400 md:block">—</span>
    <a
      v-if="link"
      class="mr-[32px] items-center gap-[8px] text-[16px] leading-[24px] text-indigo-500 md:flex"
      :href="link"
    >
      {{ description }}
      <IconArrowRight class="inline shrink-0" />
    </a>
    <button
      aria-label="Close"
      class="absolute right-[16px] p-[4px] text-indigo-400 md:right-[32px] md:p-[8px]"
      @click="emit('hide-banner')"
    >
      <IconActionDeleteMedium class="md:hidden" />
      <IconActionDeleteLarge class="hidden md:block" />
    </button>
  </div>
</template>
